import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/reusables/Card';
import TechButton from 'components/reusables/TechButton';
import { fetchSendTechnicianAction } from 'actions/app.actions';
import constants from 'appConstants';
import { noop } from 'lodash-es';
import { clearLogsToDownload } from '../../actions';
import { getLogsError, getLogsForDownload } from '../../selectors';

const BeehomeLogs = ({ bhome }) => {
    const dispatch = useDispatch();

    const logsError = useSelector(getLogsError);
    const logsToDownload = useSelector(getLogsForDownload);

    useEffect(() => {
        if (!logsToDownload) {
            return;
        }

        const a = document.createElement('a');
        a.href = logsToDownload;
        a.click();
        dispatch(clearLogsToDownload());
    }, [dispatch, logsToDownload]);

    const handleDownloadLogs = useCallback(() => {
        dispatch(
            fetchSendTechnicianAction(
                bhome.id,
                {
                    type: constants.COMMANDS.GET_LOGS,
                    params: {},
                },
                noop,
                'Command sent. Please wait on this page for the download to start.'
            )
        );
    }, [bhome.id, dispatch]);

    return (
        <div className="beehome-logs-center">
            <Card className="bhome-logs" title="Beehome Logs">
                <div className="logs-section flex-line flex-line-left">
                    <TechButton className="download-logs btn-primary" onClick={handleDownloadLogs} alwaysEnabled>
                        Download logs
                    </TechButton>
                </div>
                {logsError && <div className="bhome-logs-error">{logsError}</div>}
            </Card>
        </div>
    );
};

BeehomeLogs.propTypes = {
    bhome: PropTypes.shape(),
};

export default BeehomeLogs;
