import dayjs from 'dayjs';
import { isNaN } from 'lodash-es';

const getGridOptions = () => ({
    columnDefs: [
        {
            headerName: 'Battery Voltage',
            field: 'battery_voltage',
            valueFormatter: params =>
                params.data.battery_voltage && !Number.isNaN(Number(params.data.battery_voltage))
                    ? Number(params.data.battery_voltage).toFixed(2)
                    : params.data.battery_voltage,
        },
        {
            headerName: 'Solar Panels Voltage',
            field: 'solar_panels_voltage',
            valueFormatter: params =>
                params.data.solar_panels_voltage && !Number.isNaN(Number(params.data.solar_panels_voltage))
                    ? Number(params.data.solar_panels_voltage).toFixed(2)
                    : params.data.solar_panels_voltage,
        },
        {
            headerName: 'Solar Panels Current',
            field: 'solar_panels_current',
            valueFormatter: params =>
                params.data.solar_panels_current && !Number.isNaN(Number(params.data.solar_panels_current))
                    ? Number(params.data.solar_panels_current).toFixed(2)
                    : params.data.solar_panels_current,
        },
        {
            headerName: 'Load DC Voltage',
            field: 'load_dc_voltage',
            valueFormatter: params =>
                params.data.load_dc_voltage && !Number.isNaN(Number(params.data.load_dc_voltage))
                    ? Number(params.data.load_dc_voltage).toFixed(2)
                    : params.data.load_dc_voltage,
        },
        {
            headerName: 'Load DC Current',
            field: 'load_dc_current',
            valueFormatter: params =>
                params.data.load_dc_current && !Number.isNaN(Number(params.data.load_dc_current))
                    ? Number(params.data.load_dc_current).toFixed(2)
                    : params.data.load_dc_current,
        },
        {
            headerName: 'Charging State',
            field: 'charging_state',
        },
        {
            headerName: 'SOC',
            field: 'soc',
            valueFormatter: params =>
                params.data.soc && !Number.isNaN(Number(params.data.soc))
                    ? Number(params.data.soc).toFixed(2)
                    : params.data.soc,
        },
        {
            headerName: 'Date',
            field: 'timestamp',
            valueFormatter: params => {
                if (!params.data.timestamp) {
                    return '';
                }
                if (isNaN(+params.data.timestamp)) {
                    return (
                        dayjs(params.data.timestamp.substring(0, 15), 'YYYYMMDD_HHmmss').toDate().toLocaleString() || ''
                    );
                }
                return dayjs.unix(params.data.timestamp).toDate().toLocaleString() || '';
            },
        },
    ],
});

export const solarDataKeysMap = getGridOptions().columnDefs.reduce((acc, item) => {
    acc[item.field] = item.headerName;
    return acc;
}, {});

export default getGridOptions;
