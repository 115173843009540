import { isEmpty } from 'lodash-es';

export const getHiveNumber = (frameIndex, hiveCapacity) => {
    if (frameIndex === 0) {
        return 1;
    }

    return Math.ceil(frameIndex / hiveCapacity);
};

export const SOFTWARE_UPDATE_COMMAND = 'updateConfig (SW update)';

export const getCustomTextMatcher = ({ filterOption, value, filterText, data }) => {
    if (filterText == null) {
        return false;
    }
    let commandValue = value;
    if (!isEmpty(data?.payload?.new_config?.general?.software_updates)) {
        commandValue = SOFTWARE_UPDATE_COMMAND.toLowerCase();
    }
    switch (filterOption) {
        case 'contains':
            return commandValue.indexOf(filterText) >= 0;
        case 'notContains':
            return commandValue.indexOf(filterText) < 0;
        case 'equals':
            return commandValue === filterText;
        case 'notEqual':
            // eslint-disable-next-line
            return commandValue != filterText;
        case 'startsWith':
            return commandValue.indexOf(filterText) === 0;
        case 'endsWith':
            const index = commandValue.lastIndexOf(filterText);
            return index >= 0 && index === commandValue.length - filterText.length;
        default:
            return false;
    }
};
