import { eventChannel } from 'redux-saga';
import { select, call, put, take, cancelled, cancel, fork } from 'redux-saga/effects';
import { auth } from '@beewise/react-utils';
import { API_URL } from 'config';
import constants from 'appConstants';
// eslint-disable-next-line import/no-unresolved
import io from 'socket.io-client';
import { showToast } from '@beewise/toast';
import { FETCH_SIGNIN, SIGN_OUT, SOCKET_CONNECTION_FAILED } from 'components/views/SignIn/actionTypes';
import { getCurrentBhomeId } from 'components/views/ActionsAndMonitoring/selectors';
import {
    ON_RECEIVE_NEW_LOGS,
    ON_RECEIVE_NEW_RANGE_LOGS,
    SOCKET_CONNECTED,
    SET_CURRENT_BHOME,
    ON_RECEIVE_NEW_ROBOT_STATUS,
    ON_RECEIVE_NEW_ROBOT_VERSIONS,
    ON_RECEIVE_NEW_ROBOT_READ_RFID,
    ON_UPDATE_BHOME_STATUS,
    ON_RECEIVE_LOG_ERROR,
    ON_RECEIVE_NEW_MESSAGE,
    ON_UPDATE_MESSAGE_STATUS,
    SET_LIVE_VIDEO_URL,
    ON_GET_SYSTEM_STATUSES,
    ON_CREATE_SSH_TUNNEL,
    ON_RECEIVE_LOGS_TO_DOWNLOAD,
    ON_RECEIVE_CAMERA_DEVICES,
    ON_UPDATE_CONNECTED_USERS_LIST,
    ON_FAIL_UPDATE_BHOME_STATUS,
    ON_RECEIVE_DEVICES,
    ON_UPDATE_CONFIG,
    ON_FRAME_SCALE_CALIBRATE_INFO,
    ON_REPORT_HOURLY_SOLAR_DATA,
    ON_REPORT_SOLAR_DATA,
    ON_UPDATE_SYRUP_LEVEL,
    ON_UPDATE_HONEY_LEVEL,
    ON_UPDATE_INTERLOCK_STATUS,
    ON_GET_TEST_STATUS,
    ON_GET_AVAILABLE_TESTS,
    ON_GET_SYS_INFO,
    ON_UPDATE_BHOME_MAP,
    ON_SET_LAYOUT,
    ON_UPDATE_JENKINS_TEST_RESULTS,
    ON_REPORT_POWER_DATA,
    ON_GET_POWER_DATA,
    ON_GET_IR_TEMPERATURE,
    ON_UPDATE_GPS_LOCATION,
    ON_FRAME_SCALE_WEIGHT,
} from '../actionTypes';

let connection = null;

const connect = async (currentBhomeId = null) => {
    const user = auth.getUser();

    if (user && currentBhomeId) {
        const socket = io(`${API_URL}/technician`, {
            query: `bhomeId=${currentBhomeId}&token=${auth.getSocketJwtToken()}`,
            forceNew: true,
            transports: ['websocket'],
        });

        return new Promise(resolve => {
            socket.on('connect', () => {
                resolve(socket);
            });
            socket.on('connect_error', error => {
                if (
                    error?.message?.toLowerCase?.()?.includes?.('invalid signature') ||
                    error?.message?.toLowerCase?.()?.includes?.('malformed')
                ) {
                    resolve({ error: true });
                }
            });
        });
    }

    return null;
};

const createSocketChannel = socket =>
    eventChannel(emit => {
        const emitter = data => {
            emit(data);
        };
        socket.on('data', emitter);
        return () => {
            socket.off('data', emitter);
        };
    });

function* socketConnect() {
    let socket;
    let socketChannel;
    try {
        const currentBhomeId = yield select(getCurrentBhomeId);
        if (!currentBhomeId) {
            return;
        }
        socket = yield call(connect, currentBhomeId);
        if (!socket) {
            return;
        }
        if (socket.error) {
            yield put({ type: SOCKET_CONNECTION_FAILED });
            return;
        }
        yield put({ type: SOCKET_CONNECTED });
        socketChannel = yield call(createSocketChannel, socket);

        while (true) {
            const wsActionObj = yield take(socketChannel);
            const wsAction = JSON.parse(wsActionObj);
            const { event, payload } = wsAction;
            if (event === constants.EVENT_NAMES.RECEIVE_NEW_LOGS) {
                yield put({ type: ON_RECEIVE_NEW_LOGS, payload });
            } else if (event === constants.EVENT_NAMES.GET_LOGS) {
                yield put({ type: ON_RECEIVE_LOGS_TO_DOWNLOAD, payload });
            } else if (event === constants.EVENT_NAMES.RECEIVE_CAMERA_DEVICES) {
                yield put({ type: ON_RECEIVE_DEVICES, payload });
            } else if (event === constants.EVENT_NAMES.GET_CAMERA_DEVICES) {
                yield put({ type: ON_RECEIVE_CAMERA_DEVICES, payload });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_RANGE_LOGS) {
                yield put({ type: ON_RECEIVE_NEW_RANGE_LOGS, payload });
            } else if (event === constants.EVENT_NAMES.LOG_ERROR) {
                yield put({ type: ON_RECEIVE_LOG_ERROR, payload });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_ROBOT_STATUS) {
                yield put({ type: ON_RECEIVE_NEW_ROBOT_STATUS, payload });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_ROBOT_VERSIONS) {
                yield put({ type: ON_RECEIVE_NEW_ROBOT_VERSIONS, payload });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_ROBOT_READ_RFID) {
                yield put({ type: ON_RECEIVE_NEW_ROBOT_READ_RFID, payload });
            } else if (event === constants.EVENT_NAMES.UPDATE_JENKINS_TEST_RESULTS) {
                yield put({ type: ON_UPDATE_JENKINS_TEST_RESULTS, payload });
            } else if (event === constants.EVENT_NAMES.UPDATE_BHOME_STATUS) {
                yield put({
                    type: ON_UPDATE_BHOME_STATUS,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_BHOME_MAP) {
                yield put({
                    type: ON_UPDATE_BHOME_MAP,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.RECEIVE_NEW_MESSAGE) {
                yield put({ type: ON_RECEIVE_NEW_MESSAGE, payload });
            } else if (event === constants.EVENT_NAMES.UPDATE_MESSAGE_STATUS) {
                yield put({ type: ON_UPDATE_MESSAGE_STATUS, payload });
            } else if (event === constants.EVENT_NAMES.TOGGLE_LIVE_VIDEO) {
                yield put({ type: SET_LIVE_VIDEO_URL, payload });
            } else if (event === constants.EVENT_NAMES.GET_SYSTEM_STATUSES) {
                yield put({
                    type: ON_GET_SYSTEM_STATUSES,
                    payload,
                    bhomeId: wsAction.id,
                });
            } else if (event === constants.EVENT_NAMES.CREATE_SSH_TUNNEL) {
                yield put({
                    type: ON_CREATE_SSH_TUNNEL,
                    payload,
                    bhomeId: wsAction.id,
                });
            } else if (event === constants.EVENT_NAMES.CLOSE_SSH_TUNNEL) {
                yield put({
                    type: ON_CREATE_SSH_TUNNEL,
                    payload: null,
                    bhomeId: wsAction.id,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_CONNECTED_USERS_LIST) {
                yield put({ type: ON_UPDATE_CONNECTED_USERS_LIST, payload });
            } else if (event === constants.EVENT_NAMES.UPDATE_CONFIG) {
                yield put({ type: ON_UPDATE_CONFIG, payload });
            } else if (event === constants.EVENT_NAMES.FRAME_SCALE_CALIBRATE_INFO) {
                yield put({ type: ON_FRAME_SCALE_CALIBRATE_INFO, payload });
            } else if (event === constants.EVENT_NAMES.FRAME_SCALE_WEIGHT) {
                yield put({ type: ON_FRAME_SCALE_WEIGHT, payload });
            } else if (event === constants.EVENT_NAMES.REPORT_HOURLY_SOLAR_DATA) {
                yield put({ type: ON_REPORT_HOURLY_SOLAR_DATA, payload });
            } else if (event === constants.EVENT_NAMES.REPORT_SOLAR_DATA) {
                yield put({ type: ON_REPORT_SOLAR_DATA, payload });
            } else if (event === constants.EVENT_NAMES.UPDATE_GPS_LOCATION) {
                yield put({ type: ON_UPDATE_GPS_LOCATION, bhomeId: wsAction.bhomeId, gps: wsAction.gps });
            } else if (event === constants.EVENT_NAMES.REPORT_POWER_DATA) {
                yield put({ type: ON_REPORT_POWER_DATA, payload });
            } else if (event === constants.EVENT_NAMES.GET_POWER_DATA) {
                yield put({ type: ON_GET_POWER_DATA, payload });
            } else if (event === constants.EVENT_NAMES.UPDATE_INTERLOCK_STATUS) {
                yield put({ type: ON_UPDATE_INTERLOCK_STATUS, payload });
            } else if (event === constants.EVENT_NAMES.FAIL_UPDATE_BHOME_STATUS) {
                const error = `Failed update bhome status. Error message: ${payload?.data?.error_info?.error_message}, task description: ${payload?.data?.error_info?.task_description}, sequence info: ${payload?.data?.error_info?.sequence_info}`;
                showToast(error, {
                    toastType: 'toast-error',
                    position: 'bottom-center',
                });
                yield put({
                    type: ON_FAIL_UPDATE_BHOME_STATUS,
                    payload,
                    bhomeId: wsAction.id,
                });
            } else if (
                event === constants.EVENT_NAMES.UPDATE_SYRUP_LEVEL ||
                event === constants.EVENT_NAMES.UPDATE_SYRUP_LEVEL_SENSORS
            ) {
                yield put({
                    type: ON_UPDATE_SYRUP_LEVEL,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.UPDATE_HONEY_LEVEL) {
                yield put({
                    type: ON_UPDATE_HONEY_LEVEL,
                    payload,
                    updatedBhome: wsAction.updatedBhome,
                });
            } else if (event === constants.EVENT_NAMES.GET_TEST_STATUS) {
                yield put({ type: ON_GET_TEST_STATUS, payload });
            } else if (event === constants.EVENT_NAMES.GET_IR_TEMPERATURE) {
                yield put({ type: ON_GET_IR_TEMPERATURE, payload });
            } else if (event === constants.EVENT_NAMES.GET_AVAILABLE_TESTS) {
                yield put({ type: ON_GET_AVAILABLE_TESTS, payload });
            } else if (event === constants.EVENT_NAMES.GET_SYS_INFO) {
                yield put({
                    type: ON_GET_SYS_INFO,
                    payload,
                });
            } else if (event === constants.EVENT_NAMES.SET_LAYOUT) {
                yield put({ type: ON_SET_LAYOUT, data: { ...payload.data } });
            }
        }
    } finally {
        if (yield cancelled()) {
            connection = null;
            if (socket) {
                socket.close();
            }
            if (socketChannel) {
                socketChannel.close();
            }
        }
    }
}

function* disconnect() {
    yield cancel(connection);
}

function* selectCurrentBhome() {
    yield cancel(connection);
    connection = yield fork(socketConnect);
}

const customTakeEvery = (pattern, saga, ...args) =>
    // eslint-disable-next-line func-names
    fork(function* () {
        while (true) {
            const action = yield take(pattern);
            connection = yield fork(saga, ...args.concat(action));
        }
    });

export default function* root() {
    connection = yield fork(socketConnect);
    yield customTakeEvery(FETCH_SIGNIN.success, socketConnect);
    yield customTakeEvery(SET_CURRENT_BHOME, selectCurrentBhome);
    yield customTakeEvery(SIGN_OUT, disconnect);
}
