import constants from 'appConstants';
import { isEmpty } from 'lodash-es';
import {
    ON_RECEIVE_NEW_LOGS,
    ON_RECEIVE_NEW_RANGE_LOGS,
    ON_RECEIVE_LOG_ERROR,
    ON_RECEIVE_LOGS_TO_DOWNLOAD,
    CLEAR_LOGS_TO_DOWNLOAD,
    ON_RECEIVE_NEW_ROBOT_READ_RFID,
} from 'actionTypes';
import { CLEAR_LOGS, FINISH_LOG_STREAM, FETCH_READ_RFID } from '../actionTypes';
import { FETCH_SEND_TECHNICIAN_ACTION, MODIFY_MODE } from '../../ActionsAndMonitoring/actionTypes';

const FETCHING_STRING = 'Fetching...';

const initialState = {
    logsToDownload: '',
    logs: '',
    logsText: '',
    logsError: null,
    logsStopTime: null,
    rfid: {
        value: null,
        lastRead: null,
        frameType: null,
    },
    mode: {},
};

const specialFeatures = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_READ_RFID.success:
            return {
                ...state,
                rfid: {
                    value: FETCHING_STRING,
                    lastRead: new Date().getTime(),
                },
            };
        case ON_RECEIVE_NEW_ROBOT_READ_RFID:
            return {
                ...state,
                rfid: {
                    value: action.payload.data.rfid,
                    frameType: action.payload.data.frame_type,
                    lastRead: new Date().getTime(),
                },
            };
        case ON_RECEIVE_NEW_LOGS:
            let logsToApply =
                state.logs.length && state.logsText.length
                    ? `${state.logs}${action.payload.data}`
                    : action.payload.data;

            // if stream stop happened
            if (state.logsStopTime) {
                logsToApply = state.logs;
            }

            return {
                ...state,
                logs: logsToApply,
                logsText: FETCHING_STRING,
                logsError: null,
            };
        case ON_RECEIVE_LOGS_TO_DOWNLOAD:
            return {
                ...state,
                logsToDownload: action.payload,
            };
        case CLEAR_LOGS_TO_DOWNLOAD:
            return {
                ...state,
                logsToDownload: '',
            };
        case ON_RECEIVE_NEW_RANGE_LOGS:
            return {
                ...state,
                logs: action.payload.data,
                logsText: '',
                logsError: null,
                logsStopTime: null,
            };
        case ON_RECEIVE_LOG_ERROR:
            return {
                ...state,
                logs: '',
                logsText: '',
                logsStopTime: null,
                logsError: action.payload.error,
            };
        case FINISH_LOG_STREAM:
            return {
                ...state,
                logsText: '',
                logsStopTime: null,
            };
        case CLEAR_LOGS:
            return {
                ...state,
                logs: '',
                logsText: '',
                logsError: null,
                logsStopTime: null,
            };
        case FETCH_SEND_TECHNICIAN_ACTION.success:
            let changedState = {};
            const downloadLogsTypes = ['file', 'hb', 'jetson', 'all-files', 'all-jetson'];
            if (
                action.data.type === constants.COMMANDS.GET_LOGS &&
                !downloadLogsTypes.includes(action.data.getLogsType)
            ) {
                changedState = {
                    logsText: FETCHING_STRING,
                    logsError: null,
                    logsStopTime: null,
                };
            } else if (action.data.type === constants.COMMANDS.STOP_LOG_STREAM) {
                changedState = {
                    logsText: '',
                    logsError: null,
                    logsStopTime: Date.now(),
                };
            }

            if (!isEmpty(changedState)) {
                return {
                    ...state,
                    ...changedState,
                };
            }

            return state;
        case MODIFY_MODE.success:
            return {
                ...state,
                mode: action.data.mode,
            };
        default:
            return state;
    }
};

export default specialFeatures;
