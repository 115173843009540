import {
    FETCH_BHOME_CONNECTIVITY_MAP,
    FETCH_LATEST_BHOMES_ERRORS,
    FETCH_CONNECTIVITY_MAP_DATA,
    FETCH_UPDATE_BHOME_NOTE,
    FETCH_SEND_UPDATE_STATUSES,
    FETCH_SEND_ACTION,
    FETCH_MONITORING_DATA,
} from '../actionTypes';

export const fetchBhomeConnectivityMap = (showFetchIndicator = true) => ({
    type: 'FETCH',
    params: {
        type: FETCH_BHOME_CONNECTIVITY_MAP.default,
        url: '/bhomes/connectivity-map',
        showFetchIndicator,
    },
});
export const fetchMonitoringData = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_MONITORING_DATA.default,
        url: '/bhomes/v4/monitoring',
        showFetchIndicator: false,
    },
});

export const fetchSendUpdateStatuses = (bhomes, status, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_SEND_UPDATE_STATUSES.default,
        url: '/bhomes/bulk-update-statuses',
        body: {
            bhomes,
            status,
        },
        resolver,
        toastText: 'Sent successfully',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchBulkAction = (bhomeIds, action, resolver) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: FETCH_SEND_ACTION.default,
        url: '/bhomes/v4/actions/bulk',
        body: {
            bhomeIds,
            action,
        },
        resolver,
        toastText: 'Sent successfully',
        toastOptions: {
            toastType: 'toast-success',
            position: 'bottom-center',
            buttonText: null,
        },
    },
});

export const fetchUpdateBhomeNote = (id, note) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_BHOME_NOTE.default,
        method: 'PUT',
        url: `/bhomes/${id}/update-note`,
        body: {
            note,
        },
    },
});

export const fetchLatestBhomesErrors = (showFetchIndicator = true) => ({
    type: 'FETCH',
    params: {
        type: FETCH_LATEST_BHOMES_ERRORS.default,
        url: '/bhomes/latest-bhomes-error',
        showFetchIndicator,
    },
});

export const fetchConnectivityMapData = (showFetchIndicator = true) => ({
    type: 'FETCH',
    params: {
        type: FETCH_CONNECTIVITY_MAP_DATA.default,
        url: '/messages/technician/get-connectivity-map-data?days=0.5',
        showFetchIndicator,
    },
});
